import { getWeek, getYear } from "date-fns";
import { nl } from "date-fns/locale";
import { defineStore } from "pinia";
import { coachApi } from "@/lib/backend";
import type { GroepslesResultaat } from "@/lib/backend/coach.api";
import type { LesUitgebreid } from "@/lib/backend/coach.api";

type GroepslesState = {
	aankomend: LesUitgebreid[];
	actueel: LesUitgebreid[];
};

export const useGroepslesStore = defineStore("groepsles", {
	state: (): GroepslesState => ({
		aankomend: [],
		actueel: [],
	}),
	actions: {
		async get(options?: { groepslesId?: number }): Promise<GroepslesResultaat | undefined> {
			if (!options?.groepslesId) {
				return;
			}

			const response = await coachApi.api.groepslessenDetail(options.groepslesId);

			switch (response.status) {
				case 200: {
					return response.data;
				}

				default: {
					throw response;
				}
			}
		},
		async getGroepslessen({
			date = new Date(),
			groepslesId,
			vestigingNaam,
		}: {
			date?: Date;
			groepslesId?: number;
			vestigingNaam?: string;
		} = {}): Promise<LesUitgebreid[]> {
			const response = await coachApi.api.lessenWeekYearCreate(
				getWeek(date, { locale: nl }),
				getYear(date),
				{
					...(vestigingNaam && { vestiging_naam: vestigingNaam }),
					...(groepslesId && { groepsles: groepslesId }),
				},
			);

			switch (response.status) {
				case 200: {
					return response.data;
				}

				default: {
					throw response;
				}
			}
		},
		async getAankomendeGroepslessen(): Promise<LesUitgebreid[]> {
			const response = await coachApi.api.lessenAankomendCreate();

			switch (response.status) {
				case 200: {
					this.aankomend = response.data;

					return this.aankomend;
				}

				default: {
					throw response;
				}
			}
		},
	},
});
